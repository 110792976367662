/* FADE ANIMATIONS */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  -webkit-transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  transition: opacity 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  -webkit-transition: opacity 200ms;
  -moz-transition: opacity 200ms;
  transition: opacity 200ms;
}
